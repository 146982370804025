import React, { useState } from "react";
import {
  FaTh,
  FaTasks,
  FaUsers,
  FaMoneyBill,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import logo from "../../assets/sidebar/logo.png";
import avatar from "../../assets/sidebar/avatar.jpg";
import { Link, useNavigate } from "react-router-dom";
import "../layout/Sidebar.css";

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true); // State for sidebar open/close
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState<string>(() => {
    const storedMenu = localStorage.getItem("activeMenu");
    return storedMenu ? storedMenu : "Dashboard";
  });

  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle the sidebar open/close
  };

  return (
    <>
      {/* Sidebar Container */}
      <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
        {/* Logo Section */}
        <div className="sidebar-logo">
          <img src={logo} alt="Logo" className="logo" />
          {/* Button to open and close the sidebar */}
          <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>

        {/* Navigation Items */}
        <ul className="sidebar-nav">
          <li
            className={`nav-item ${activeMenu === "Dashboard" ? "active" : ""}`}
          >
            <FaTh className="nav-icon" />
            {isOpen && (
              <Link
                to="/layout/dashboard"
                className="nav-text"
                onClick={() => setActiveMenu("Dashboard")}
              >
                Dashboard
              </Link>
            )}
          </li>
          <li
            className={`nav-item ${
              activeMenu === "Task Management" ? "active" : ""
            }`}
          >
            <FaTasks className="nav-icon" />
            {isOpen && (
              <Link
                to="./components/Auth/Taskmanager"
                className="nav-text"
                onClick={() => setActiveMenu("Task Management")}
              >
                Task Management
              </Link>
            )}
          </li>
          <li
            className={`nav-item ${
              activeMenu === "Employee Management" ? "active" : ""
            }`}
          >
            <FaUsers className="nav-icon" />
            {isOpen && (
              <Link
                to="/layout/employees"
                className="nav-text"
                onClick={() => setActiveMenu("Employee Management")}
              >
                Employee Management
              </Link>
            )}
          </li>
          <li
            className={`nav-item ${activeMenu === "Payment" ? "active" : ""}`}
          >
            <FaMoneyBill className="nav-icon" />
            {isOpen && (
              <Link
                to="/layout/payment"
                className="nav-text"
                onClick={() => setActiveMenu("Payment")}
              >
                Payment
              </Link>
            )}
          </li>
          <li
            className={`nav-item ${activeMenu === "Element" ? "active" : ""}`}
          >
            <FaMoneyBill className="nav-icon" />
            {isOpen && (
              <Link
                to="/layout/element"
                className="nav-text"
                onClick={() => setActiveMenu("Element")}
              >
                Element
              </Link>
            )}
          </li>
          <li
            className={`nav-item ${activeMenu === "Element-1" ? "active" : ""}`}
          >
            <FaMoneyBill className="nav-icon" />
            {isOpen && (
              <Link
                to="/layout/element1"
                className="nav-text"
                onClick={() => setActiveMenu("Element-1")}
              >
                Element-1
              </Link>
            )}
          </li>
          <li
            className={`nav-item ${
              activeMenu === "Job Report" ? "active" : ""
            }`}
          >
            <FaMoneyBill className="nav-icon" />
            {isOpen && (
              <Link
                to="/layout/element"
                className="nav-text"
                onClick={() => setActiveMenu("Job Report")}
              >
                Job report
              </Link>
            )}
          </li>
        </ul>

        {/* User Section */}
        {isOpen && (
          <div className="sidebar-user">
            <img src={avatar} alt="User" className="user-avatar" />
            <div className="user-info">
              <p className="user-name">Senthil</p>
              <p className="user-email">singasafe@gmail.com</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
