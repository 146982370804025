import React, { useState } from "react";
import "./Employeemanagement.css";
import searchIcon from "../../assets/search.svg"; // Your search icon path
import contactIcon from "../../assets/contact.png"; // Path to the uploaded contact icon
import phoneIcon from "../../assets/phone.png"; // Path to the phone icon

const employees = [
  {
    id: 1,
    name: "Shaliya",
    role: "Manager",
    phone: "9876543210",
    color: "teal",
  },
  {
    id: 2,
    name: "Prem Nazeer",
    role: "Manager",
    phone: "9876543211",
    color: "blue",
  },
  {
    id: 3,
    name: "Sharan Ravi",
    role: "Manager",
    phone: "111-523-0123",
    color: "red",
  },
  {
    id: 4,
    name: "Venkatesh",
    role: "Manager",
    phone: "202-555-0123",
    color: "purple",
  },
  {
    id: 5,
    name: "Rajesh Kumar",
    role: "Employee",
    phone: "202-555-0123",
    color: "orange",
  },
  {
    id: 6,
    name: "Muthuvel",
    role: "Employee",
    phone: "202-555-0432",
    color: "black",
  },
  {
    id: 7,
    name: "Mohan Raj",
    role: "Employee",
    phone: "111-523-0123",
    color: "blue",
  },
  {
    id: 8,
    name: "Sakthi Velan",
    role: "Employee",
    phone: "202-555-0123",
    color: "teal",
  },
];

const EmployeeManagement: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("team");

  const filteredEmployees = employees.filter((employee) =>
    employee.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="employee-management">
      <div className="employee-header">
        <h1 className="employee-management-title">Employee Management</h1>
        <div className="employee-tab-bar">
          <button
            className={`employee-tab ${activeTab === "team" ? "active" : ""}`}
            onClick={() => setActiveTab("team")}
          >
            Team Member
          </button>
          <button
            className={`employee-tab ${activeTab === "create" ? "active" : ""}`}
            onClick={() => setActiveTab("create")}
          >
            Create Member
          </button>
        </div>
      </div>
      <div className="employee-header-controls">
        <div className="employee-search-bar">
          <img
            src={searchIcon}
            alt="search-icon"
            className="employee-search-icon"
          />
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <select className="employee-filter">
          <option value="all">All Employees</option>
        </select>
      </div>
      <div className="employee-list">
        {filteredEmployees.map((employee) => (
          <div className="employee-card" key={employee.id}>
            <div
              className="employee-icon"
              style={{ backgroundColor: employee.color }}
            >
              <img
                src={contactIcon}
                alt="employee-avatar"
                className="employee-avatar"
              />
            </div>
            <h2 className="employee-name">{employee.name}</h2>
            <p className="employee-role">{employee.role}</p>
            <p className="employee-phone">
              <img src={phoneIcon} alt="phone-icon" className="phone-icon" />{" "}
              {employee.phone}
            </p>
            <button className="employee-view-profile-btn">View profile</button>
          </div>
        ))}
      </div>
      <div className="employee-pagination">
        <button className="employee-page-btn">{"<"}</button>
        <button className="employee-page-btn active">1</button>
        <button className="employee-page-btn">2</button>
        <button className="employee-page-btn">3</button>
        <button className="employee-page-btn">{">"}</button>
      </div>
    </div>
  );
};

export default EmployeeManagement;
