import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Login.css";
import logo from "../../assets/sidebar/logo.png";
import avatar2 from "../../assets/login av.png";

const Login: React.FC = () => {
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate(); // Initialize useNavigate

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Phone:", phone);
    console.log("Password:", password);

    // Perform login logic here (e.g., validation, API call)

    // Redirect to layout/element page
    navigate("/layout/element");
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <img src={logo} alt="Logo" className="login-logo" />
        <h2 className="login-title">Login</h2>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="login-form-group">
            <input
              type="text"
              id="phone"
              placeholder="Phone Number"
              value={phone}
              onChange={handlePhoneChange}
              required
              className="login-input"
            />
          </div>
          <div className="login-form-group">
            <input
              type="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
              className="login-input"
            />
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
        </form>
      </div>
      <div className="login-right">
        <img src={avatar2} alt="Avatar" className="login-avatar" />
      </div>
    </div>
  );
};

export default Login;
