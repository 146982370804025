import React from "react";
import "./dashboard.css";
import tick from "../../assets/tick.png";
import filter from "../../assets/filter.png";

const Dashboard: React.FC = () => {
  const paymentDetails = [
    {
      title: "Invoice",
      amount: "₹1,38,340",
      records: 4,
      className: "dashboard-invoice",
    },
    {
      title: "Received",
      amount: "₹2,38,540",
      records: 24,
      className: "dashboard-received",
    },
    {
      title: "Pending",
      amount: "₹1,80,834",
      records: 4,
      className: "dashboard-pending",
    },
    {
      title: "Quotation",
      amount: "₹5,90,345",
      records: 8,
      className: "dashboard-quotation",
    },
    {
      title: "Quo - Conversion",
      amount: "₹4,90,342",
      records: 4,
      className: "dashboard-conversion",
    },
  ];

  const leadDetails = [
    "Hot",
    "Warm",
    "Cold",
    "Client Call",
    "Client Visit",
    "Client Follow",
    "Total Quotation",
    "Quotation Conversion",
    "Over Due",
    "Quotation Request",
  ];

  const topPerformanceDetails = [
    {
      category: "Employee",
      items: [
        "Rajeshwaran",
        "Shalya",
        "Venkatesan",
        "Jaya Suriya",
        "Prem Nazeer",
      ],
    },
    {
      category: "Manager",
      items: [
        "Rajeshwaran",
        "Shalya",
        "Venkatesan",
        "Jaya Suriya",
        "Prem Nazeer",
      ],
    },
    {
      category: "Location",
      items: ["Chennai", "Madurai", "Coimbatore", "Pollachi", "Tirupur"],
    },
  ];

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Dashboard</h1>
        <button className="dashboard-create-task">+ Create Task</button>
      </div>
      <div className="dashboard-section">
        <h2>Payment Details</h2>
        <div className="dashboard-payment-details">
          {paymentDetails.map((item, index) => (
            <div key={index} className={`dashboard-card ${item.className}`}>
              <div className="dashboard-card-header">
                <h3>{item.title}</h3>
                <div className="dashboard-card-icon">₹</div>
              </div>
              <p className="dashboard-card-amount">{item.amount}</p>
              <button className="dashboard-card-records">
                {item.records} records
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="dashboard-section">
        <div className="dashboard-section-header">
          <h2>Lead Details</h2>
          <button className="dashboard-filter-button">
            <img src={filter} alt="Filter" className="filter-icon" />
            Filter
          </button>
        </div>
        <div className="dashboard-lead-details">
          {leadDetails.map((item, index) => (
            <div key={index} className="dashboard-lead-item">
              <div className="dashboard-lead-header">
                <div className="dashboard-lead-image">
                  <img src={tick} alt="Lead Icon" />
                </div>
                <p className="dashboard-lead-text">{item}</p>
              </div>
              <p className="dashboard-lead-value">10</p>
            </div>
          ))}
        </div>
      </div>
      <div className="dashboard-section performance-section">
        <div className="dashboard-section-header">
          <h2>Top 5 Performance Details</h2>
          <div className="dashboard-performance-legend">
            <div className="dashboard-legend-item">
              <input
                type="radio"
                name="performance"
                id="top-performance"
                defaultChecked
                className="dashboard-radio top-performance"
              />
              <label htmlFor="top-performance">Top Performance</label>
            </div>
            <div className="dashboard-legend-item">
              <input
                type="radio"
                name="performance"
                id="low-performance"
                className="dashboard-radio low-performance"
              />
              <label htmlFor="low-performance">Low Performance</label>
            </div>
            <button className="dashboard-filter-button">
              <img src={filter} alt="Filter" className="filter-icon" />
              Filter
            </button>
          </div>
        </div>
        <div className="dashboard-performance-details">
          {topPerformanceDetails.map((category, index) => (
            <div key={index} className="dashboard-performance-column">
              <h3>{category.category}</h3>
              <ul>
                <li className="column-header">Name</li>
                {category.items.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>{" "}
    </div>
  );
};

export default Dashboard;
