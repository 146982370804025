import React, { useState } from "react";
import "./Element.css";
import searchIcon from "../../assets/search.svg";
import contactIcon from "../../assets/contact.png";
import map from "../../assets/map.jpg";
import sales from "../../assets/sales-avatar.jpg";
import service from "../../assets/service-avatar.jpg";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import card from "../../assets/card.png";
import {
  FaUser,
  FaPhone,
  FaCalendarAlt,
  FaClock,
  FaMapMarkerAlt,
  FaLocationArrow,
} from "react-icons/fa";

const employees = [
  {
    id: 1,
    name: "Shaliya",
    role: "Manager",
    phone: "9876543210",
    color: "teal",
  },
];

interface Task {
  name: string;
  area: string;
  location: string;
  contact: string;
  service: string;
}

interface Tasks {
  unassigned: Task[];
  assigned: Task[];
  completed: Task[];
  team: Task[];
  create: Task[];
}

const Element: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState<keyof Tasks | "create" | "team">(
    "unassigned"
  );
  // graph const staart
  const data = {
    labels: ["Assigned jobs", "Completed Jobs"],
    datasets: [
      {
        label: "Service Percentage",
        data: [1000, 445],
        backgroundColor: ["#f3d1d1", "#e64343"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
      },
    },
    cutout: "70%",
    responsive: true,
    maintainAspectRatio: true,
  };
  // {graph const end}

  const tasks: Tasks = {
    unassigned: [
      {
        name: "Customer Name",
        area: "Coimbatore",
        location: "134.9625234",
        contact: "9876543210",
        service: "General Service",
      },
      {
        name: "Customer Name",
        area: "Chennai",
        location: "134.9625234",
        contact: "9876543210",
        service: "General Service",
      },
      {
        name: "Customer Name",
        area: "Madurai",
        location: "134.9625234",
        contact: "9876543210",
        service: "General Service",
      },
      {
        name: "Customer Name",
        area: "Assam",
        location: "134.9625234",
        contact: "9876543210",
        service: "General Service",
      },
      {
        name: "Customer Name",
        area: "South Delhi",
        location: "134.9625234",
        contact: "9876543210",
        service: "General Service",
      },
      {
        name: "Customer Name",
        area: "West Delhi",
        location: "134.9625234",
        contact: "9876543210",
        service: "General Service",
      },
    ],
    assigned: [
      {
        name: "Customer Name",
        area: "Bangalore",
        location: "12.345678",
        contact: "9876543210",
        service: "Repair Service",
      },
      {
        name: "Customer Name",
        area: "Mumbai",
        location: "12.345678",
        contact: "9876543210",
        service: "Installation",
      },
    ],
    completed: [
      {
        name: "Customer Name",
        area: "Hyderabad",
        location: "98.765432",
        contact: "9876543210",
        service: "Maintenance",
      },
    ],
    team: [],
    create: [],
  };

  const filteredEmployees = employees.filter((employee) =>
    employee.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="task-manager">
      <div className="task-header">
        <h1 className="task-title">Task Management</h1>
        <button className="create-task-btn">+ Create Task</button>
      </div>
      <div className="task-tabs">
        {["unassigned", "assigned", "completed"].map((tab) => (
          <div
            key={tab}
            className={`task-tab ${activeTab === tab ? "active" : ""}`}
            onClick={() => setActiveTab(tab as keyof Tasks)}
          >
            <span className="capitalize">
              <span
                className={`custom-checkbox ${
                  activeTab === tab ? "checked" : ""
                }`}
              >
                {activeTab === tab && "✔"}
              </span>{" "}
              {tab} Task
            </span>
          </div>
        ))}
      </div>
      <div className="task-table-wrapper">
        <table className="task-table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Area</th>
              <th>Location</th>
              <th>Contact Number</th>
              <th>Service Type</th>
              <th>Update Status</th>
            </tr>
          </thead>
          <tbody>
            {tasks[activeTab as keyof Tasks] &&
              tasks[activeTab as keyof Tasks].map(
                (task: Task, index: number) => (
                  <tr key={index}>
                    <td>{task.name}</td>
                    <td>{task.area}</td>
                    <td>
                      <a href="#" className="location-link">
                        {task.location}
                      </a>
                    </td>
                    <td>{task.contact}</td>
                    <td>
                      <span className="service-pill">{task.service}</span>
                    </td>
                    <td>
                      <button className="update-btn">✎</button>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
      </div>
      <div className="employee-list">
        {filteredEmployees.map((employee) => (
          <div className="employee-card" key={employee.id}>
            <div
              className="employee-icon"
              style={{ backgroundColor: employee.color }}
            >
              <img
                src={contactIcon}
                alt="employee-avatar"
                className="employee-avatar"
              />
            </div>
            <h2 className="employee-name">{employee.name}</h2>
            <p className="employee-role">{employee.role}</p>
            <p className="employee-phone">
              <i className="fa fa-phone"></i> {employee.phone}
            </p>
            <button className="employee-view-profile-btn">View profile</button>
          </div>
        ))}
      </div>
      <div className="employee-header">
        <h1 className="employee-management-title">Employee Management</h1>
        <div className="employee-tab-bar">
          <button
            className={`employee-tab ${activeTab === "team" ? "active" : ""}`}
            onClick={() => setActiveTab("team")}
          >
            Team Member
          </button>
          <button
            className={`employee-tab ${activeTab === "create" ? "active" : ""}`}
            onClick={() => setActiveTab("create")}
          >
            Create Member
          </button>
        </div>
      </div>
      <div className="employee-header-controls">
        <div className="employee-search-bar">
          <img
            src={searchIcon}
            alt="search-icon"
            className="employee-search-icon"
          />
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <select className="employee-filter">
          <option value="all">All Employees</option>
        </select>
      </div>
      <div className="employee-pagination">
        <button className="employee-page-btn">{"<"}</button>
        <button className="employee-page-btn active">1</button>
        <button className="employee-page-btn">2</button>
        <button className="employee-page-btn">3</button>
        <button className="employee-page-btn">{">"}</button>
      </div>
      <div className="payment-page">
        {/* Left Container */}
        <div className="left-container">
          <div className="left-con-profile">
            <div className="left-con-profile-image">
              <img src={contactIcon} alt="Profile" className="profile-image" />
            </div>
            <div className="left-con-profile-details">
              <h3 className="left-con-profile-name">Jaya Suriya</h3>
              <div className="left-con-profile-item-head">
                <div className="left-con-profile-item">
                  <FaPhone className="left-con-profile-icon" /> +91 9876543210
                </div>
                <div className="left-con-profile-item">
                  <FaCalendarAlt className="left-con-profile-icon" /> Friday,
                  December 16, 2024
                </div>
                <div className="left-con-profile-item">
                  <FaClock className="left-con-profile-icon" /> 09:00 AM - 11:00
                  PM
                </div>
                <div className="left-con-profile-item">
                  <FaMapMarkerAlt className="left-con-profile-icon" /> 55,
                  Gandhi Nagar, Coimbatore
                </div>
                <div className="left-con-profile-item">
                  <FaLocationArrow className="left-con-profile-icon" /> Current
                  Location: 234.243584326023
                </div>
              </div>
            </div>
          </div>
          <div className="left-con-map">
            <img src={map} alt="Map" className="left-con-map-image" />
          </div>
          <div className="left-con-payment">
            <h2 className="left-con-payment-title">Payment Details</h2>
            <div className="left-con-payment-info">
              <div className="left-con-payment-row">
                <span className="left-con-payment-label">Ref number</span>
                <span className="left-con-payment-value">0000072697027</span>
              </div>
              <div className="left-con-payment-row">
                <span className="left-con-payment-label">Date</span>
                <span className="left-con-payment-value">09-05-2024</span>
              </div>
              <div className="left-con-payment-row">
                <span className="left-con-payment-label">Time</span>
                <span className="left-con-payment-value">05:00 AM</span>
              </div>
              <div className="left-con-payment-row">
                <span className="left-con-payment-label">Payment method</span>
                <span className="left-con-payment-value">Credit card</span>
              </div>
              <div className="left-con-payment-row">
                <span className="left-con-payment-label">Amount</span>
                <span className="left-con-payment-value">₹590</span>
              </div>
            </div>
            <button className="left-con-payment-button">Get PDF receipt</button>
          </div>
        </div>

        {/* Right Container */}
        <div className="right-container">
          <h2 className="right-con-title">Task Assign</h2>
          <div className="right-con-assign">
            <select className="right-con-select">
              <option value="">Assign Person</option>
              {employees.map((employee) => (
                <option value={employee.id} key={employee.id}>
                  {employee.name}
                </option>
              ))}
            </select>
            <button className="right-con-button">Submit</button>
          </div>
        </div>
      </div>
      <div className="overdue-requests">
        <h2>Overdue Requests</h2>
        <div className="overdue-request-item">
          <div className="client-info">
            <FaUser className="client-icon" />
            <div className="client-details">
              <span className="client-name">Client Name</span>
              <span className="client-city">Coimbatore</span>
              <span className="client-contact">234.23452346562</span>
            </div>
          </div>
          <div className="service-info">
            <button className="service-button">Service</button>
            <span className="service-date">05/08/2024</span>
          </div>
        </div>
        <div className="overdue-request-item">
          <div className="client-info">
            <FaUser className="client-icon" />
            <div className="client-details">
              <span className="client-name">Client Name</span>
              <span className="client-city">Chennai</span>
              <span className="client-contact">234.2345258662</span>
            </div>
          </div>
          <div className="service-info">
            <button className="service-button">Service</button>
            <span className="service-date">05/08/2024</span>
          </div>
        </div>
      </div>{" "}
      <div className="cards-container">
        <div className="admin-card admin-sales">
          <h3 className="admin-sales-title">Sales</h3>
          <img src={sales} alt="Sales" className="admin-sales-image" />
        </div>

        <div className="admin-card admin-service">
          <h3 className="admin-service-title">Service</h3>
          <img src={service} alt="Service" className="admin-service-image" />
        </div>
      </div>
      <div className="service-payment-container">
        <div className="service-payment-header">
          <i className="service-payment-icon">
            <img src={card} alt="Payment Icon" />
          </i>
          <h4 className="service-payment-title">Payment method</h4>
        </div>
        <div className="service-payment-options">
          <div className="service-payment-row">
            <label className="service-payment-option">
              <input type="radio" name="payment" value="Hand" />
              <span>Hand</span>
            </label>
            <label className="service-payment-option">
              <input type="radio" name="payment" value="UPI" />
              <span>UPI</span>
            </label>
          </div>
          <div className="service-payment-row">
            <label className="service-payment-option">
              <input type="radio" name="payment" value="IMPS" />
              <span>IMPS</span>
            </label>
            <label className="service-payment-option">
              <input type="radio" name="payment" value="NIFTY" />
              <span>NIFTY</span>
            </label>
          </div>
          <div className="service-payment-row">
            <label className="service-payment-option">
              <input type="radio" name="payment" value="Others" />
              <span>Others</span>
            </label>
          </div>
        </div>
      </div>
      <div className="graph-container">
        <div className="graph-details">
          <h3>Performance Details</h3>
          <p>Employee Name: Prem Nazeer</p>
          <p>Emp ID: CHE1073</p>
          <p>Assigned Job: 150</p>
          <p>Completed Job: 80</p>
          <p>Over Due Job: 55</p>
          <p>Upcoming Job: 10</p>
          <p>Reschedule Job: 5</p>
          <p>Invoice Raise Value: 3,00,000</p>
          <p>Payment Collected: 1,00,000</p>
          <p>Location: Chennai</p>
        </div>
      </div>
    </div>
  );
};

export default Element;
