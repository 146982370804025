import React, { useState } from "react";
import "./Taskmanager.css";

interface Task {
  name: string;
  area: string;
  location: string;
  contact: string;
  service: string;
  employee?: string; // Optional employee field for tasks
}

interface Tasks {
  unassigned: Task[];
  assigned: Task[];
  completed: Task[];
}

const TaskManager: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    "unassigned" | "assigned" | "completed"
  >("unassigned");

  const tasks: Tasks = {
    unassigned: [
      {
        name: "Customer Name",
        area: "Coimbatore",
        location: "134.9625234",
        contact: "9876543210",
        service: "General Service",
      },
      {
        name: "Customer Name",
        area: "Chennai",
        location: "134.9625234",
        contact: "9876543210",
        service: "General Service",
      },
      {
        name: "Customer Name",
        area: "Madurai",
        location: "134.9625234",
        contact: "9876543210",
        service: "General Service",
      },
      {
        name: "Customer Name",
        area: "Assam",
        location: "134.9625234",
        contact: "9876543210",
        service: "General Service",
      },
      {
        name: "Customer Name",
        area: "South Delhi",
        location: "134.9625234",
        contact: "9876543210",
        service: "General Service",
      },
      {
        name: "Customer Name",
        area: "West Delhi",
        location: "134.9625234",
        contact: "9876543210",
        service: "General Service",
      },
    ],
    assigned: [
      {
        name: "Customer Name",
        area: "Bangalore",
        location: "12.345678",
        contact: "9876543210",
        service: "Repair Service",
        employee: "John Doe",
      },
      {
        name: "Customer Name",
        area: "Mumbai",
        location: "12.345678",
        contact: "9876543210",
        service: "Installation",
        employee: "Jane Smith",
      },
    ],
    completed: [
      {
        name: "Customer Name",
        area: "Hyderabad",
        location: "98.765432",
        contact: "9876543210",
        service: "Maintenance",
        employee: "Emily Clark",
      },
    ],
  };

  return (
    <div className="task-manager">
      <div className="task-header">
        <h1 className="task-title">Task Management</h1>
        <button className="create-task-btn">+ Create Task</button>
      </div>

      <div className="task-tabs">
        {["unassigned", "assigned", "completed"].map((tab) => (
          <div
            key={tab}
            className={`task-tab ${activeTab === tab ? "active" : ""}`}
            onClick={() =>
              setActiveTab(tab as "unassigned" | "assigned" | "completed")
            }
          >
            <span className="custom-checkbox">{activeTab === tab && "✔"}</span>
            <span className="tab-text">
              {tab === "unassigned" && "Un Assigned Task"}
              {tab === "assigned" && "Assigned Task"}
              {tab === "completed" && "Completed Task"}
            </span>
          </div>
        ))}
      </div>

      <div className="task-table-wrapper">
        <table className="task-table">
          <thead>
            <tr>
              {activeTab !== "unassigned" && <th>Employee Name</th>}
              <th>Customer Name</th>
              <th>Area</th>
              <th>Location</th>
              <th>Contact Number</th>
              <th>Service Type</th>
              {activeTab !== "completed" && <th>Update Status</th>}
            </tr>
          </thead>
          <tbody>
            {tasks[activeTab].map((task, index) => (
              <tr key={index}>
                {activeTab !== "unassigned" && <td>{task.employee}</td>}
                <td>{task.name}</td>
                <td>{task.area}</td>
                <td>
                  <a href="#" className="location-link">
                    {task.location}
                  </a>
                </td>
                <td>{task.contact}</td>
                <td>
                  <span className="service-pill">{task.service}</span>
                </td>
                {activeTab !== "completed" && (
                  <td>
                    <button className="update-btn">✎</button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TaskManager;
