import React from "react";
import "./App.css";
import Layout from "./Layout";
import { AuthProvider } from "./context/AuthContext";
import Sidebar from "./components/layout/Sidebar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TaskManager from "./components/Auth/Taskmanager";
import Employeemanagement from "./components/Auth/Employeemanagement";
import Element from "./components/Auth/Element";
import Element1 from "./components/Auth/Element1";
import Login from "./components/Auth/Login";
import Jobreport from "./components/Auth/Jobreport";
import Dashboard from "./components/pages/dashboard";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/layout" element={<Layout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="taskmanager" element={<TaskManager />} />
            <Route path="Employeemanagement" element={<Employeemanagement />} />
            <Route path="Element" element={<Element />} />
            <Route path="Element1" element={<Element1 />} />
            <Route path="Jobreport" element={<Jobreport />} />
          </Route>
          <Route path="/" element={<Sidebar />} />

          {/* Add a default route to handle the root path */}
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
