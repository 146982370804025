import React from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import "./Element1.css";

const Element1: React.FC = () => {
  const chartData = {
    labels: ["Assigned jobs", "Completed Jobs"],
    datasets: [
      {
        data: [1000, 445],
        backgroundColor: ["#FFB6C1", "#FF6347"],
        hoverBackgroundColor: ["#FF69B4", "#FF4500"],
        borderColor: "#FFF",
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false },
    },
    cutout: "65%",
    responsive: true,
    maintainAspectRatio: false,
  };

  interface PerformanceChartProps {
    title: string;
  }

  const PerformanceChart: React.FC<PerformanceChartProps> = ({ title }) => (
    <div className="performance-graph-chart">
      <h4 className="graph-title">{title}</h4>
      <div className="graph-chart-wrapper">
        <Doughnut data={chartData} options={chartOptions} />
        <div className="graph-chart-center">
          <p>Service Percentage</p>
          <span className="graph-percentage">45%</span>
        </div>
      </div>
      <div className="graph-legend-bottom">
        <div className="graph-legend-column">
          <div className="graph-legend">
            <span className="graph-dot graph-assigned"></span>Assigned jobs
          </div>
          <div className="graph-legend">
            <span className="graph-dot graph-completed"></span>Completed Jobs
          </div>
        </div>
        <div className="graph-value-column">
          <div className="graph-value">1,000</div>
          <div className="graph-value">445</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="performance-graph-container">
      <div className="performance-graph-section">
        <div className="graph-performance-details">
          <h3 className="graph-title">Performance Details</h3>
          <div className="graph-details-list">
            <p>
              <span>Employee Name:</span>{" "}
              <span className="graph-value">Ram Nanduri</span>
            </p>
            <p>
              <span>Emp ID:</span> <span className="graph-value">CHE571</span>
            </p>
            <p>
              <span>Assigned Job:</span>{" "}
              <span className="graph-value">150</span>
            </p>
            <p>
              <span>Completed Job:</span>{" "}
              <span className="graph-value">100</span>
            </p>
            <p>
              <span>Over Due Job:</span> <span className="graph-value">25</span>
            </p>
            <p>
              <span>Upcoming Job:</span> <span className="graph-value">50</span>
            </p>
            <p>
              <span>Mechanic Job:</span> <span className="graph-value">5</span>
            </p>
            <p>
              <span>Invoice Total Value:</span>{" "}
              <span className="graph-value">1,50,000</span>
            </p>
          </div>
        </div>
        <PerformanceChart title="Performance" />
      </div>

      <div className="performance-graph-section">
        <div className="graph-team-performance">
          <h3 className="graph-title">Team Performance</h3>
          <div className="graph-details-list">
            <p>
              <span>Team Count:</span> <span className="graph-value">09</span>
            </p>
            <p>
              <span>Assigned job:</span>{" "}
              <span className="graph-value">800</span>
            </p>
            <p>
              <span>Completed Job:</span>{" "}
              <span className="graph-value">500</span>
            </p>
            <p>
              <span>Over Due job:</span> <span className="graph-value">89</span>
            </p>
            <p>
              <span>Upcoming job:</span>{" "}
              <span className="graph-value">300</span>
            </p>
            <p>
              <span>Mechanic Job:</span> <span className="graph-value">36</span>
            </p>
            <p>
              <span>Invoice Total Value:</span>{" "}
              <span className="graph-value">5,00,000</span>
            </p>
            <p>
              <span>Payment Collected:</span>{" "}
              <span className="graph-value">3,60,000</span>
            </p>
          </div>
        </div>
        <PerformanceChart title="Team Performance" />
      </div>
    </div>
  );
};

export default Element1;
