import React, { useState } from "react";
import "./Jobreport.css";
import { Button, Table, Input, Radio, Checkbox } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import upload from "../../assets/upload.png";

const JobReport: React.FC = () => {
  // Sample data for the table
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const data = [
    {
      key: "1",
      name: "Employee 1",
      assignedJob: 93,
      completedJob: 1,
      overdueJob: 1,
      location: "Chennai",
      managerName: "Manager Name",
    },
    {
      key: "2",
      name: "Employee 2",
      assignedJob: 53,
      completedJob: 2,
      overdueJob: 2,
      location: "Chennai",
      managerName: "Manager Name",
    },
    {
      key: "3",
      name: "Employee 2",
      assignedJob: 53,
      completedJob: 5,
      overdueJob: 2,
      location: "Chennai",
      managerName: "Manager Name",
    },
    {
      key: "4",
      name: "Employee 2",
      assignedJob: 53,
      completedJob: 2,
      overdueJob: 5,
      location: "Chennai",
      managerName: "Manager Name",
    },
    {
      key: "6",
      name: "Employee 2",
      assignedJob: 53,
      completedJob: 6,
      overdueJob: 5,
      location: "Chennai",
      managerName: "Manager Name",
    },
    {
      key: "7",
      name: "Employee 2",
      assignedJob: 53,
      completedJob: 7,
      overdueJob: 3,
      location: "Chennai",
      managerName: "Manager Name",
    },
    {
      key: "8",
      name: "Employee 2",
      assignedJob: 53,
      completedJob: 3,
      overdueJob: 5,
      location: "Chennai",
      managerName: "Manager Name",
    },
    {
      key: "9",
      name: "Employee 2",
      assignedJob: 53,
      completedJob: 4,
      overdueJob: 10,
      location: "Chennai",
      managerName: "Manager Name",
    },
    {
      key: "10",
      name: "Employee 2",
      assignedJob: 53,
      completedJob: 8,
      overdueJob: 2,
      location: "Chennai",
      managerName: "Manager Name",
    },
    // Add more rows as needed...
  ];

  // Handle select all checkbox change
  const onSelectAllChange = (e: any) => {
    const { checked } = e.target;
    const newSelectedKeys = checked ? data.map((item) => item.key) : [];
    setSelectedRowKeys(newSelectedKeys);
  };

  // Table columns definition
  const columns = [
    {
      title: (
        <Checkbox
          indeterminate={
            selectedRowKeys.length > 0 && selectedRowKeys.length < data.length
          }
          checked={selectedRowKeys.length === data.length}
          onChange={onSelectAllChange}
        />
      ),
      dataIndex: "checkbox",
      key: "checkbox",
      width: 50, // Small width for checkbox
      render: (_: any, record: any) => (
        <Checkbox
          checked={selectedRowKeys.includes(record.key)}
          onChange={() => {
            const newSelectedKeys = selectedRowKeys.includes(record.key)
              ? selectedRowKeys.filter((key) => key !== record.key)
              : [...selectedRowKeys, record.key];
            setSelectedRowKeys(newSelectedKeys);
          }}
        />
      ),
    },
    { title: "Employee Name", dataIndex: "name", key: "name" },
    { title: "Assigned Job", dataIndex: "assignedJob", key: "assignedJob" },
    { title: "Completed Job", dataIndex: "completedJob", key: "completedJob" },
    { title: "Overdue Job", dataIndex: "overdueJob", key: "overdueJob" },
    { title: "Location", dataIndex: "location", key: "location" },
    { title: "Manager Name", dataIndex: "managerName", key: "managerName" },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: () => <span className="action-dots">...</span>,
    },
  ];

  return (
    <div className="report-management-container">
      <header className="report-management-header">
        <div className="header-left">
          <h2 className="report-title">Report Management</h2>
          <h3 className="report-subtitle">Service - Job Report</h3>
        </div>
        <Button className="create-task-button">+ Create Task</Button>
      </header>

      <div className="report-filters">
        <Input
          className="search-input"
          placeholder="Search"
          prefix={<SearchOutlined />}
        />
        <select className="category-select">
          <option>Category</option>
        </select>
        <Button className="filter-button">Filter</Button>
        <Radio.Group className="view-radio-group" defaultValue="employee">
          <Radio value="managers">Managers</Radio>
          <Radio value="employee">Employee</Radio>
        </Radio.Group>
        <Button className="export-button">
          <img
            src={upload}
            alt="Export"
            className="export-icon"
          />{" "}
          Export
        </Button>
      </div>

      <Table
        className="report-table"
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default JobReport;
